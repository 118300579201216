import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                "We took our grandson, age 6 and quite full of energy, here recently. What a wonderful place for kids! He loved the climbing wall, the basketball hoop, the zip line, the cargo net - you name it, he did it multiple times. It is a calm environment, not overstimulating. Highly recommend”.

”                            </p>
                            <p className="overview">- Christina</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “The staff at the Westford location went above and beyond to make our son's 5th birthday, one to remember. They were accommodating and incredibly helpful with anything that was needed. We can't thank them enough for helping in creating an amazing experience.”
                            </p>
                            <p className="overview">- Mohamed </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “My toddler loved playing here! The owners are very friendly and I found the place to be clean and safe. There were so many great activities: an infant/toddler space, equipment to swing on, a trampoline, a zip line, an arts & crafts section, and tons of different toys to choose from. It’s a great fun place to bring the kids.”
                           </p>
                            <p className="overview">
                                - Michelle</p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                                “My son had such a blast here!! He made some new friends who were a great fit for him. We wish we lived closer or we would be here every day! 10 out of 10 recommend! There’s something for every kid, toddlers through teens. Parents, there’s a resource room where you can sit and hang out too!”
 </p>
                            <p className="overview">
                                - Kristin
                                                        </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
